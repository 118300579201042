import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Button, makeStyles, Typography, Grid } from '@material-ui/core';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1rem',
    width: '100%',

    marginTop: '2rem',
    border: '2px solid #E5EAF4',
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: { height: 'auto', paddingBottom: '2rem' },
  },
  image: {
    height: '175px',
    width: '175px',
    [theme.breakpoints.down('xs')]: {
      height: '125px',
    },
  },
  content: {
    paddingLeft: '2rem',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  imageContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

export const AllSoftwareCard = ({ software }) => {
  const classes = useStyles();
  const { logo, _rawSynopsis, slug, allSolutionsCta } = software;
  return (
    <Grid className={classes.card} container>
      <Grid
        item
        lg={2}
        container
        alignItems='center'
        justifyContent='center'
        className={classes.imageContainer}
      >
        <GatsbyImage
          image={logo?.asset.gatsbyImageData}
          alt={slug.current}
          className={classes.image}
          imgStyle={{ objectFit: 'contain' }}
        />
      </Grid>

      <Grid
        item
        container
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
        lg={10}
        className={classes.content}
      >
        <PortableText
          content={_rawSynopsis}
          serializers={{
            h4: ({ children }) => (
              <Typography
                variant='h5'
                color='primary'
                style={{ fontWeight: 700, marginBottom: '.5rem' }}
              >
                {children}
              </Typography>
            ),
            normal: ({ children }) => (
              <Typography variant='body1' style={{ marginBottom: '1rem' }}>
                {children}
              </Typography>
            ),
          }}
        />
        <Link to={`/${slug.current}`}>
          <Button variant='contained' color='secondary' size='large'>
            {allSolutionsCta}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};
