import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { InPageBot } from '@leadoo/react-bot';

import { Container, useMediaQuery } from '@material-ui/core';

import { GeneralHero } from '../components/General/GeneralHero';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { Resources } from '../components/General/Resources';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { SectionWaveDefault } from '../components/Waves/SectionWaveDefault';
import { SectionLight } from '../components/Sections/SectionLight';
import { ProductOverview } from '../components/ProductFeature/ProductOverview';
import { SEO } from '../components/SEO';
import Form from '../components/ContactForm/Form';

import LocaleContext from '../context/LocaleContext';
import { AllSoftwareCard } from '../components/AllSoftware/AllSoftwareCard';

const AllSoftwarePage = ({ data: { allSoftware }, location }) => {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const {
    _rawCustomerLogosHeader,
    customerLogos,
    testimonialHeader,
    testimonials,
    resourceTitle,
    resources,
    _rawResourceBody,
    hero,
    contactForm,
    pardotUrl,
    _rawOverviewContent,
    software,
    metaTitle,
    metaDescription,
    leadoo,
  } = allSoftware;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  const md = useMediaQuery('(max-width: 960px)');
  const video = testimonials.length ? testimonials[0]?.videoVariant : null;

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      <SectionWhite>
        <Container>
          <ProductOverview overviewBody={_rawOverviewContent} />

          <div>
            {software.map((software, index) => (
              <AllSoftwareCard key={index} software={software} />
            ))}
          </div>
          <div style={{ paddingTop: '4rem' }}>
            <InPageBot code={leadoo.botId} seamless={leadoo.seamless} />
          </div>
        </Container>
      </SectionWhite>

      <SectionBlue
        extraTop={!video}
        top={video}
        additionalTop={video && md ? 60 : video ? 80 : null}
        marginBottom='-220px'
      >
        <SectionWaveDefault top='0px' fillColorShape='#FFF' />
        <Testimonials
          testimonials={testimonials}
          header={testimonialHeader}
          background
        />
      </SectionBlue>
      {customerLogos && (
        <SectionWhite top additionalTop={220} extraBottom>
          <CustomerLogos
            customerLogosArray={customerLogos}
            customerLogosHeader={_rawCustomerLogosHeader}
          />
          <SectionWaveDefault
            flipHorizontal={false}
            flipVertical={true}
            bottom='0px'
            fillColorShape='#F5F9FF'
            fillColorBorder='#F5F9FF'
          />
        </SectionWhite>
      )}
      <SectionLight top={md} bottom={md}>
        <Form
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          location={location}
        />
      </SectionLight>
      <SectionWhite top additionalTop={100} additionalBottom={100} zIndex={3}>
        <SectionWaveDefault
          top='0px'
          fillColorBorder='#FFF'
          flipHorizontal={true}
        />
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>
    </>
  );
};

export default AllSoftwarePage;

export const query = graphql`
  query ($slug: String!) {
    allSoftware: sanityAllSoftware(slug: { current: { eq: $slug } }) {
      metaTitle
      metaDescription
      _type
      id
      slug {
        current
      }
      leadoo {
        botId
        seamless
      }
      language
      hero {
        softwareLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        showEmailForm
      }
      _rawOverviewContent
      software {
        slug {
          current
        }
        logo {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              fit: FILLMAX
              height: 250
              width: 250
            )
          }
        }
        allSolutionsCta
        _rawSynopsis
      }
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        title
      }
      testimonialHeader
      testimonials {
        title
        testimonialText
        nameAndTitle
        company
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        videoVariant
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
    }
  }
`;
